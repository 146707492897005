var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-input"},[_c('the-mask',{ref:"accountNumber",staticClass:"el-input__inner",attrs:{"mask":[
      '######',
      '#######',
      '########',
      '#########',
      '##########',
      '###########',
      '############',
      '#############',
      '##############',
      '###############',
      '################',
      '#################'
    ],"type":"tel","autocomplete":"off","name":"account","masked":true,"value":_vm.value},nativeOn:{"input":function($event){return _vm.updateInput($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }